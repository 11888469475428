import { isEmpty } from 'lodash-es'
import type { ServerNotifyMessagePayload } from '#core/types/packages/socket'
import type { Field, FieldSyncPayload } from '#field/types'
import { FIELD_LOADER_FRAGMENT } from '#field/loader_fragment'
import { BOARD_CACHE, ModelListCache } from '#core/cache'
import { FIELD_TYPE_NAME, FieldType } from '#field/constant'

class FieldListCache extends ModelListCache<Field> {
  constructor() {
    super(FIELD_TYPE_NAME)
  }

  override getBoardCacheKeys() {
    return [{
      key: BOARD_CACHE.DATA,
      subKey: 'fields',
    }]
  }
}

export const useFieldSync = () => {
  const { client } = useApolloClient()
  const { currentBoard } = useWorkspaceSharedState()
  const listCache = new FieldListCache()
  const { getFieldIdentifier, getSyncFieldFields, normalizeParams } = useFieldDataConvert()

  const onAdd = async (payload: FieldSyncPayload) => {
    const params = normalizeParams(payload)
    client.cache.writeFragment({
      data: params,
      fragment: FIELD_LOADER_FRAGMENT,
      fragmentName: 'FieldLoader',
    })
    listCache.add(currentBoard.value.id, params)
  }

  const onUpdate = (payload: FieldSyncPayload) => {
    const params = normalizeParams(payload)
    client.cache.modify({
      id: getFieldIdentifier(payload.id),
      fields: getSyncFieldFields(params),
    })
  }

  const onDelete = (payload: FieldSyncPayload) => {
    client.cache.evict({
      id: getFieldIdentifier(payload.id),
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: FieldSyncPayload) => void
        >([
          ['ADD', onAdd],
          ['UPDATE', onUpdate],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
    listCache,
  }
}
