import { pick } from 'lodash-es'
import { TASK_TYPE_TYPE_NAME } from '#task-type/constant'
import type { BaseTaskType, TaskTypeUpdatePayload } from '#task-type/types'
import { TASK_TYPE_FRAGMENT } from '#task-type/fragment'
import { getIdentifier, createFieldModifiers } from '#core/utils/apollo'
import type { FieldsModifier } from '#core/types'

export const useTaskTypeDataConvert = () => {
  const { client } = useApolloClient()
  const getTaskTypeIdentifier = (id: string) => getIdentifier(id, TASK_TYPE_TYPE_NAME)
  const fields = ['name', 'icon', 'background', 'level', 'description', 'fields', 'settingsPackId']

  const getTaskTypeObject = (id: string) => (
    client.readFragment<BaseTaskType & { fields: [] }>(
      {
        id,
        fragment: TASK_TYPE_FRAGMENT,
        fragmentName: 'TaskType',
      },
      true
    )
  )

  const getOptimisticTaskType = (id: string | null, payload: TaskTypeUpdatePayload, forFields = false): Partial<BaseTaskType> & { __typename?: string } => {
    const defaults = { fields: [] }
    const taskType = getOptimisticObject(id, payload, fields, defaults, forFields)
    return forFields ? taskType : { ...taskType, __typename: TASK_TYPE_TYPE_NAME }
  }

  const getOptimisticTaskTypeFields = (payload: TaskTypeUpdatePayload) => {
    const taskType = getOptimisticTaskType(null, payload, true)
    return convertObjectToFields(taskType)
  }

  const getSyncTaskTypeFields = (payload: TaskTypeUpdatePayload) => {
    const fieldModifiers = createFieldModifiers(fields, payload)
    return pick(fieldModifiers, Object.keys(payload)) as FieldsModifier<BaseTaskType>
  }

  return {
    getTaskTypeIdentifier,
    getTaskTypeObject,
    getOptimisticTaskType,
    getOptimisticTaskTypeFields,
    getSyncTaskTypeFields,
  }
}
