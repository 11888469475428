export const TaskTypeIconPresets = [
  'heroicons:cube-20-solid',
  'heroicons:bars-3-bottom-left-20-solid',
  'heroicons:check-20-solid',
  'heroicons:bug-ant-20-solid',
  'leanbase:subtask',
  'heroicons:bell-20-solid',
  'heroicons:chat-bubble-oval-left-20-solid',
  'heroicons:clock-20-solid',
  'heroicons:star-20-solid',
  'heroicons:question-mark-circle-20-solid',
  'heroicons:heart-20-solid',
  'heroicons:arrow-up-20-solid',
  'heroicons:plus-20-solid',
  'heroicons:minus-20-solid',
  'heroicons:users-20-solid',
  'heroicons:exclamation-triangle-20-solid',
  'heroicons:flag-20-solid',
  'heroicons:lock-closed-20-solid',
  'heroicons:currency-dollar-20-solid',
  'heroicons:bolt-20-solid',
  'heroicons:bookmark-20-solid',
  'heroicons:sparkles-20-solid',
  'heroicons:square-2-stack-20-solid',
  'heroicons:trophy-20-solid',
  'heroicons:tag-20-solid',
  'heroicons:rocket-launch-20-solid',
  'heroicons:fire-20-solid',
  'heroicons:folder-open-20-solid',
  'heroicons:code-bracket-20-solid',
  'heroicons:paint-brush-20-solid',
  'heroicons:megaphone-20-solid',
  'heroicons:envelope-20-solid',
  'heroicons:light-bulb-20-solid',
  'heroicons:funnel-20-solid',
  'heroicons:chart-pie-20-solid',
]

export const TaskTypeSuggestions = [
  {
    name: 'Task',
    description: 'Define a task or action to be performed',
    icon: 'heroicons:bars-3-bottom-left-20-solid',
    background: 'dark-blue',
    categories: ['Engineering & Product', 'IT', 'Support'],
  },
  {
    name: 'Bug',
    description: 'Bugs track problems or errors',
    icon: 'heroicons:bug-ant-20-solid',
    background: 'dark-red',
    categories: ['Engineering & Product', 'IT', 'Support'],
  },
  {
    name: 'User Story',
    description: 'Detail requirements or features from an end-user perspective',
    icon: 'heroicons:bookmark-20-solid',
    background: 'green',
    categories: ['Engineering & Product', 'Support'],
  },
  {
    name: 'Request',
    description: 'Handle incoming requests or tasks from clients or team members',
    icon: 'heroicons:chat-bubble-oval-left-20-solid',
    background: 'dark-green',
    categories: ['Engineering & Product', 'IT', 'Support'],
  },
  {
    name: 'Goal',
    description: 'Set and monitor progress towards specific objectives',
    icon: 'heroicons:trophy-20-solid',
    background: 'orange',
    categories: ['Personal Use', 'PMO'],
  },
  {
    name: 'Objective',
    description: 'Define clear, measurable targets to be achieved',
    icon: 'heroicons:star-20-solid',
    background: 'yellow',
    categories: ['Personal Use', 'PMO'],
  },
  {
    name: 'Campaign',
    description: 'Plan and monitor marketing or promotional campaigns',
    icon: 'heroicons:bolt-20-solid',
    background: 'purple',
    categories: ['Creative & Design', 'Marketing', 'Sales'],
  },
  {
    name: 'Content',
    description: 'Manage creation and publication of content pieces',
    icon: 'heroicons:bars-3-bottom-left-20-solid',
    background: 'blue',
    categories: ['Creative & Design', 'Marketing'],
  },
]

export const TASK_TYPE_TYPE_NAME = 'TaskTypeType'
