import { isArray } from 'lodash-es'
import type { ServerNotifyMessagePayload } from '#core/types/packages/socket'
import { BOARD_CACHE, ModelListCache } from '#core/cache'
import type { BaseTaskType } from '#task-type/types'
import { TASK_TYPE_LOADER_FRAGMENT } from '#task-type/loader_fragment'
import { FIELD_LOADER_FRAGMENT } from '#field/loader_fragment'
import { TASK_TYPE_TYPE_NAME } from '#task-type/constant'
import type { TaskTypeFieldSync, Field } from '#field/types'

class TaskTypeListCache extends ModelListCache<BaseTaskType> {
  constructor() {
    super(TASK_TYPE_TYPE_NAME)
  }

  override getBoardCacheKeys() {
    return [{
      key: BOARD_CACHE.DATA,
      subKey: 'taskTypes',
    }]
  }
}

export const useTaskTypeSync = () => {
  const { client } = useApolloClient()
  const { currentBoard } = useWorkspaceSharedState()
  const listCache = new TaskTypeListCache()
  const { getTaskTypeIdentifier, getSyncTaskTypeFields } = useTaskTypeDataConvert()

  const onAdd = async (payload: Partial<BaseTaskType & { settingsPackId: string, fields: [] }>) => {
    payload = { ...payload, fields: [] }
    client.cache.writeFragment({
      data: payload,
      fragment: TASK_TYPE_LOADER_FRAGMENT,
      fragmentName: 'TaskTypeLoader',
    })

    listCache.add(currentBoard.value.id, payload)
  }

  const onUpdate = (payload: Partial<BaseTaskType>) => {
    client.cache.modify({
      id: getTaskTypeIdentifier(payload.id as string),
      fields: getSyncTaskTypeFields(payload),
    })
  }

  const onDelete = (payload: Partial<BaseTaskType>) => {
    client.cache.evict({
      id: getTaskTypeIdentifier(payload.id as string),
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: Partial<BaseTaskType>) => void
        >([
          ['ADD', onAdd],
          ['UPDATE', onUpdate],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
    listCache,
  }
}

export const useTaskTypeFieldSync = () => {
  const { client } = useApolloClient()
  const { getTaskTypeIdentifier, getTaskTypeObject } = useTaskTypeDataConvert()
  const { listCache } = useFieldSync()
  const { currentBoard } = useWorkspaceSharedState()

  const onAdd = (payload: Partial<TaskTypeFieldSync>) => {
    client.cache.modify({
      id: getTaskTypeIdentifier(payload?.taskTypeId as string),
      fields: {
        fields: (existingFields = []) => {
          const { field } = payload
          if (isArray(field?.options)) {
            field.options = JSON.stringify(field.options)
          }

          const newFieldRef = client.cache.writeFragment({
            data: field,
            fragment: FIELD_LOADER_FRAGMENT,
            fragmentName: 'FieldLoader',
          })
          return [...existingFields, newFieldRef]
        },
      },
    })

    if (payload.field) {
      listCache.add(currentBoard.value.id, payload.field)
    }
  }

  const onDelete = (payload: Partial<TaskTypeFieldSync>) => {
    const taskTypeIdentifier = getTaskTypeIdentifier(payload.taskTypeId as string)
    const taskType = getTaskTypeObject(taskTypeIdentifier)
    if (!taskType) {
      return logger.log('Task type not found')
    }

    const currentFields = taskType.fields
    const newFields = currentFields.filter((field: Field) => field.id !== payload.fieldId)
    client.cache.modify({
      id: taskTypeIdentifier,
      fields: {
        fields: () => newFields,
      },
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: Partial<TaskTypeFieldSync>) => void
        >([
          ['ADD', onAdd],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
  }
}
